.reopen-chat-modal {
    width: 550px !important;
    border-radius: 0px !important;
}
.reopen-chat-modal .title {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    margin: 25px;
    margin-top: 5px;
}

.reopen-chat-modal .grid .row .column {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}

.reopen-chat-modal .grid {
    margin-top: 30px;
}

.reopen-chat-modal .grid .row .column:first-child {
    color: #B2B2B2;
}

.reopen-chat-modal .grid .row .column.disabled:last-child {
    color: #B2B2B2;
}

.reopen-chat-modal .grid .row .column:last-child {
    color: #23B062;
}

/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    .reopen-chat-modal {
        width: auto !important;
    }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {  }

/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {  }

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {  }

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {}
